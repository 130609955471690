window.xmOptions = {
    fetchUrl: '/shop/mobile/xm-options.json',
    cpcActive: true,
    cpcNativeShimUrl: 'https://common-payment.int.xfinity.com/2.1.2/jump/native-shim.js'
};

if (window.xmOptions.cpcActive && window.xmOptions.cpcNativeShimUrl) {
    const cpcNativeShimScript = window.document.createElement('script');
    cpcNativeShimScript.type = 'text/javascript';
    cpcNativeShimScript.src = window.xmOptions.cpcNativeShimUrl;
    cpcNativeShimScript.async = true;
    window.document.head.appendChild(cpcNativeShimScript);
};
